class Forms {
    constructor() {
        this.init();
    }

    init() {
        this.forms = document.querySelectorAll("form");
        this.forms.forEach((form) => {
            this.addFormHandlers(form);
        });
    }

    addFormHandlers(form) {
        const numericInputs = form.querySelectorAll(".form__input--numeric");
        numericInputs.forEach((input) => {
            const decrement = input.querySelector("[data-decrement]");
            const increment = input.querySelector("[data-increment]");
            const inputField = input.querySelector("input");
            if (inputField.disabled) {
                return;
            }
            inputField.addEventListener("change", (e) => {
                const value = parseInt(inputField.value);
                if (value > inputField.max) {
                    inputField.value = inputField.max;
                } else if (value < inputField.min) {
                    inputField.value = inputField.min;
                }
            });
            decrement.addEventListener("click", (e) => {
                inputField.value = Math.max(
                    parseInt(inputField.value) - 1,
                    inputField.min
                );
                e.preventDefault();
                var event = new Event("change");
                inputField.dispatchEvent(event);
            });
            increment.addEventListener("click", (e) => {
                inputField.value = Math.min(
                    parseInt(inputField.value) + 1,
                    inputField.max
                );
                e.preventDefault();
                var event = new Event("change");
                inputField.dispatchEvent(event);
            });
        });
    }
}

export default Forms;
