import Emitter from "component-emitter";

class Formset {
    constructor(formset) {
        this.formset = formset;
        this.formList = formset.querySelector("ol");
        this.addButton = formset.querySelector("[data-add]");
        this.init();
    }

    toggleLastDeleteButton() {
        const deleteButtons = this.formset.querySelectorAll("[data-delete]");
        if (deleteButtons.length === 1) {
            [].forEach.call(deleteButtons, (el) => {
                el.classList.add("is-hidden");
            });
        } else {
            [].forEach.call(deleteButtons, (el) => {
                el.classList.remove("is-hidden");
            });
        }
    }

    bindDeletes() {
        this.toggleLastDeleteButton();
        this.formset.addEventListener("click", (e) => {
            const target = e.target.closest("[data-delete]");
            if (target) {
                if (this.getFormCount() > 1) {
                    const form = target.closest("[data-form]");
                    const occId = form.dataset.form;
                    if (occId) {
                        const deleteInput = document.createElement("input");
                        deleteInput.setAttribute(
                            "name",
                            `form-${occId}-DELETE`
                        );
                        deleteInput.setAttribute("type", "hidden");
                        deleteInput.setAttribute("value", "on");
                        form.insertBefore(deleteInput, form.firstChild);
                        form.classList.add("!hidden");
                    } else {
                        this.setFormCount(this.getFormCount() - 1);
                        form.remove();
                    }
                }
                this.toggleLastDeleteButton();
                e.preventDefault();
            }
        });
    }

    setFormCount(val) {
        this.formset.querySelector("[name=form-TOTAL_FORMS]").value = val;
    }

    getFormCount() {
        return parseInt(
            this.formset.querySelector("[name=form-TOTAL_FORMS]").value
        );
    }

    addForm() {
        const newForm = this.formset
            .querySelector("template")
            .content.cloneNode(true)
            .querySelector("*");
        console.log(newForm);
        const formRegex = RegExp(`form-__prefix__-`, "g");
        const nextId = this.getFormCount();
        newForm.innerHTML = newForm.innerHTML.replace(
            formRegex,
            `form-${nextId}-`
        );
        newForm.setAttribute("data-form", "");
        this.formList.append(newForm);
        this.setFormCount(nextId + 1);
        this.toggleLastDeleteButton();
        this.emit("change");
    }

    init() {
        if (this.addButton) {
            this.addButton.addEventListener("click", () => {
                this.addForm();
            });
        }
        this.bindDeletes();
    }
}

class DynamicFormset {
    constructor() {
        this.formsets = document.querySelectorAll("[data-dynamic-formset]");
        this.init();
    }

    init() {
        if (!this.formsets) {
            return;
        }

        this.formsets.forEach((formset) => {
            const formsetWrapper = new Formset(formset);
            formsetWrapper.on("change", () => {
                this.emit("change");
            });
        });
    }
}

Emitter(Formset.prototype);
Emitter(DynamicFormset.prototype);

export default DynamicFormset;
